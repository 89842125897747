
export const tabsInit = () => {
  const buttons = document.querySelectorAll('.button--blog');

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      console.log('click');
      if(!button.classList.contains('active')) {
        buttons.forEach(item => {
          item.classList.remove('active');
        })
        button.classList.add('active');
        if(button.classList.contains('blogHeaderActive')) {
          document.querySelector('.blogTabActive').classList.add('active');
          document.querySelector('.blogTabArchive').classList.remove('active');
        }

        if(button.classList.contains('blogHeaderArchive')) {
          document.querySelector('.blogTabActive').classList.remove('active');
          document.querySelector('.blogTabArchive').classList.add('active');
        }
      }
    })
  })
}