export function navInit() {
  jQuery(document).ready(function ($) {
    //obsługa hamburgera i elementów menu
    "use strict";

    $('.c-hamburger').on('click', function () {
      ($('.header__navBox').hasClass("is-active")) ? $('.header__navBox').removeClass("is-active"): $('.header__navBox').addClass("is-active");
      ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");

      // ($('.header__navBox').hasClass("is-active")) ? $('#logo_mobilne').html('<img src="/img/logo_czarne_mob.png" alt="">'): $('#logo_mobilne').html('<img src="/img/logo_biale_mob.png" alt="">');
    });

    $('.menu-item a').on('click', function () {
      ($('.header__navBox').hasClass("is-active")) ? $('.header__navBox').removeClass("is-active"): $('.header__navBox').addClass("is-active");
      ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");

      // ($('.header__navBox').hasClass("is-active")) ? $('#logo_mobilne').html('<img src="/img/logo_czarne_mob.png" alt="">'): $('#logo_mobilne').html('<img src="/img/logo_biale_mob.png" alt="">');
    });
  });

  const runObserver = () => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');

        if (entry.intersectionRatio > 0.4) {
          document.querySelectorAll(`nav li a`).forEach((item) => {
            item.parentElement.classList.remove('active');
          })
          document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.add('active');
        } else {
          // document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.remove('active');
        }
      });
    });

    // Track all sections that have an `id` applied
    document.querySelectorAll('section[id], footer[id]').forEach((section) => {
      observer.observe(section);
    });
  }
  window.addEventListener('DOMContentLoaded', () => {
    runObserver();
  });
  window.addEventListener('scroll', () => {
    runObserver();
  });
}