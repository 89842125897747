import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';



export function slickInit() {
  jQuery(document).ready(function ($) {
    $(".section_mainCarData__galleryMain").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.section_mainCarData__galleryNav'
    });
    $('.section_mainCarData__galleryNav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.section_mainCarData__galleryMain',
      dots: false,
      centerMode: true,
      focusOnSelect: true,
     
    });
  });

  jQuery(document).ready(function ($) {
    $(".homesite_opinion__repeater").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 2000,
      // centerMode: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
   
  });
}