import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
// stupid hack so that leaflet's images work after going through webpack
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: marker2x,
//     iconUrl: marker,
//     shadowUrl: markerShadow
// });


export const initMapScript = () => {
  function initMap() {
    // position we will use later
    var lat = 52.989681;
    var lon = 16.917042;

    // initialize map
    var map = L.map('map-here', {
      center: [lat, lon],
      zoom: 17,
      scrollWheelZoom: false,
      zoomControl: true
    });
    var greenIcon = L.icon({
      iconUrl: '/wp-content/themes/flexible-theme/assets/img/pin-gray.svg',
      shadowUrl: '',
      iconSize:     [17, 21],
      shadowSize:   [50, 64],
      iconAnchor:   [9, 22],
      shadowAnchor: [4, 62],
      popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
  });
    // set map tiles source
    L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFyYW5pYWtsIiwiYSI6ImNqcGwzd2NtYzA3ZnUzeHJwbmlqdDIydGMifQ.9LcFH_UwDWHovj83jn_h0w', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 20,
    }).addTo(map);
    // add marker to the map
  
    let marker = L.marker([lat, lon], {icon: greenIcon}).addTo(map);
    // add popup to the marker
    marker.bindPopup('<div class="box"><div class="boxLogo"><img src="/wp-content/themes/flexible-theme/assets/img/Logo_Macadamia_black.svg" alt=""></div><div class="boxContent"><div class="icon"><img src="/wp-content/themes/flexible-theme/assets/img/pin.svg" alt=""></div><div class="address">ul. Zielona 3a<br>64-800 Chodzież</div></div></div>').openPopup();
  }
  initMap();
}